<template>
  <div class="atento-content" v-if="item">
    <div class="point-date">
      <div class="point">
        <div class="center"></div>
      </div>
      <div class="line"></div>
    </div>
    <a :href="url" :target="target" class="card-day-news">
      <div class="d-flex justify-content-between align-items-center">
        <span class="kicker" v-html="item.topic"></span>
        <div class="date" v-html="time"></div>
      </div>
      <h3 v-html="item.title"></h3>
      <span class="via" v-if="item.source">
        <strong>Vía:</strong> {{ item.source }}</span
      >
    </a>
  </div>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    time: function () {
      return moment("2000-01-01")
        .add(this.item.timeTotalSeconds, "s")
        .format("h:mm a");
    },
    url: function () {
      return this.item.previewUrl
        ? this.item.previewUrl
        : "/atento#feed-" + this.item.timeTotalSeconds;
    },
    target: function () {
      return this.item.previewUrl ? "_blank" : "_self";
    },
  },
  props: {
    item: Object,
  },
};
</script>