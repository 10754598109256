<template>
  <div class="img-wrap">
    <img
      :v-if="image"
      :width="image.width"
      :height="image.height"
      :src="
        'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 ' +
        image.width +
        ' ' +
        image.height +
        '\'%3E%3C/svg%3E'
      "
      :data-src="image.url"
      class="lazyload"
      :alt="image.alt"
      :title="image.title"
      :data-index="index"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: Object,
    index: Number
  },
};
</script>
