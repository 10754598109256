var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "twentytwenty-container",
      style: _vm.containerStyle,
      on: { touchstart: _vm.startSlide, mousedown: _vm.startSlide },
    },
    [
      _c("img", {
        attrs: { src: _vm.after, alt: "antes" },
        on: {
          mousedown: function ($event) {
            $event.preventDefault()
          },
          load: _vm.setDimensions,
        },
      }),
      _vm._v(" "),
      _c("img", {
        style: _vm.beforeImgStyle,
        attrs: { src: _vm.before, alt: "ahora" },
        on: {
          mousedown: function ($event) {
            $event.preventDefault()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "twentytwenty-overlay", style: _vm.overlayStyle },
        [
          _vm.beforeLabel
            ? _c("div", { staticClass: "twentytwenty-before-label" }, [
                _vm._v("\n      " + _vm._s(_vm.beforeLabel) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.afterLabel
            ? _c("div", { staticClass: "twentytwenty-after-label" }, [
                _vm._v("\n      " + _vm._s(_vm.afterLabel) + "\n    "),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "twentytwenty-handle",
          style: _vm.handleStyle,
          attrs: { tabindex: "0" },
          on: { keydown: _vm.handleArrowNavigation },
        },
        [
          _c("div", { staticClass: "twentytwenty-arrow-left" }),
          _vm._v(" "),
          _c("div", { staticClass: "twentytwenty-arrow-right" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }