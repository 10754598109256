<template>
    <div class="video-live" v-if="isOpen && videoEmbed">
        <i class="icon-close" @click="closeVideo"></i>
        <div class="embed-responsive embed-responsive-16by9" v-html="videoEmbed"></div>
    </div>
</template>

<script>
export default {
    props: {
        videoEmbed: String,
    },

    methods: {
        closeVideo: function () {
            this.isOpen = false;
        }
    },
    data: function () {
        return {
            isOpen: true
        };
    },
};
</script>