var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c("div", { staticClass: "atento-content" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "card-day-news",
            attrs: { href: _vm.url, target: _vm.target },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center",
              },
              [
                _c("span", {
                  staticClass: "kicker",
                  domProps: { innerHTML: _vm._s(_vm.item.topic) },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "date",
                  domProps: { innerHTML: _vm._s(_vm.time) },
                }),
              ]
            ),
            _vm._v(" "),
            _c("h3", { domProps: { innerHTML: _vm._s(_vm.item.title) } }),
            _vm._v(" "),
            _vm.item.source
              ? _c("span", { staticClass: "via" }, [
                  _c("strong", [_vm._v("Vía:")]),
                  _vm._v(" " + _vm._s(_vm.item.source)),
                ])
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "point-date" }, [
      _c("div", { staticClass: "point" }, [
        _c("div", { staticClass: "center" }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }