<template>
  <div v-if="posts" data-mrf-recirculation="Tendencias">
    <h3 class="heading-title">TENDENCIAS</h3>
    <ul class="mt-4" data-epica-module-name="Tendencias">
      <li v-for="(post, idx) in posts" :key="post.id">
        <div class="d-flex">
          <div class="numberTends" v-html="idx + 1"></div>
          <div class="V_Trends">
            <a
              :href="post.url"
              class="kicker"
              :class="post.className"
              v-html="post.header"
            ></a>
            <h2>
              <a
                :href="post.url"
                :class="post.className"
                v-html="post.title"
              ></a>
            </h2>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("master", {
      posts: "trends",
    }),
  },
};
</script>
