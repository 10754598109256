<template>
  <div
    data-epica-module-name="Mis Noticias"
    v-if="hasLoaded && user"
    data-mrf-recirculation="Mis Noticias"
  >
    <div class="d-flex mb-3">
      <h3 class="heading-title empresasSect topics">
        <a
          href="https://mas.larepublica.co/noticias"
          target="_blank"
          rel="noopener"
        >
          MIS NOTICIAS
        </a>
      </h3>
      <div class="flex-grow-1 lineTitle m-3"></div>
    </div>
    <div class="topics notTopics mb-5" v-if="!news || !news.length">
      <div class="row">
        <div class="col-7">
          <div class="text">
            <span>Usted no ha seleccionado ningún tema de su interés</span>
            <p>
              Lo invitamos a que seleccione los temas de su preferencia para
              acceder directamente a las noticias de su interés.
            </p>
          </div>
        </div>
        <div class="col-5 columns">
          <ul class="stepsTopics">
            <li>
              <strong>Paso 1: </strong>Acceda a
              <a
                href="https://mas.larepublica.co/noticias"
                target="_blank"
                rel="noopener"
                >mas.larepublica.co</a
              >
            </li>
            <li>
              <strong>Paso 2: </strong>Ingrese a <b>"temas de interés"</b>.
            </li>
            <li>
              <strong>Paso 3: </strong>Busque y seleccione los temas de su
              preferencia.
            </li>
            <li>
              <strong>Paso 4: </strong>Disfrute de las noticas de su interés.
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="button">
            <a
              href="https://mas.larepublica.co/noticias/editar-top-20"
              target="_blank"
              rel="noopener"
              class="btn mytopicSect"
              ><i class="icon-mode_edit"></i>ADMINISTRE SUS TEMAS</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="topics oneTopics notTopics mb-5" v-else-if="news.length < 5">
      <div class="row">
        <div class="col-8 columns text">
          <div class="contentText">
            <h-img-v-title-lead :post="news[0]" group-name="hh" />
          </div>
        </div>
        <div class="col-4 columns">
          <p class="invite">
            Lo invitamos a que seleccione los temas de su preferencia para
            acceder directamente a las noticias de su interés.
          </p>
          <ul class="stepsTopics">
            <li>
              <strong>Paso 1: </strong>Acceda a
              <a
                href="https://mas.larepublica.co/noticias"
                target="_blank"
                rel="noopener"
                >mas.larepublica.co</a
              >
            </li>
            <li>
              <strong>Paso 2: </strong>Ingrese a <b>"temas de interés".</b>
            </li>
            <li>
              <strong>Paso 3: </strong>Busque y seleccione los temas de su
              preferencia.
            </li>
            <li>
              <strong>Paso 4: </strong>Disfrute de las noticas de su interés.
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="button">
            <a
              href="https://mas.larepublica.co/noticias/editar-top-20"
              target="_blank"
              rel="noopener"
              class="btn mytopicSect"
              ><i class="icon-mode_edit"></i>ADMINISTRE SUS TEMAS</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="topics fiveTopics mb-5" v-else-if="news.length < 8">
      <div class="row">
        <div class="col-4 mb-0">
          <i-img-title :post="news[0]" group-name="ee" />
        </div>
        <div class="col-8">
          <div class="row row-cols-2 second">
            <div class="col" v-for="p in news.slice(1, 5)" :key="p.id">
              <h-img-title-small :post="p" group-name="eb" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="button">
            <a
              href="https://mas.larepublica.co/noticias"
              target="_blank"
              rel="noopener"
              class="btn mytopicSect"
              >MIS NOTICIAS</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="allTopics mb-5">
      <div class="row">
        <div class="col-5 mb-0">
          <i-img-title :post="news[0]" group-name="ee" />
        </div>
        <div class="col-4 d-flex flex-column justify-content-between">
          <h-img-title-small
            v-for="p in news.slice(1, 4)"
            :key="p.id"
            :post="p"
            group-name="eb"
          />
        </div>
        <div class="col-3 d-flex flex-column justify-content-between">
          <v-title
            v-for="p in news.slice(4, 9)"
            :key="p.id"
            :post="p"
            group-name="ec"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="button">
            <a
              href="https://mas.larepublica.co/noticias"
              target="_blank"
              rel="noopener"
              class="btn mytopicSect"
              >MIS NOTICIAS</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HImgVTitleLead from "../../post-components/h-img-v-title-lead/HImgVTitleLead.vue";
import HImgTitleSmall from "../../post-components/h-img-title-small/HImgTitleSmall.vue";
import IImgTitle from "../../post-components/i-img-title/IImgTitle.vue";
import VTitle from "../../post-components/v-title/VTitle.vue";

export default {
  components: { HImgVTitleLead, HImgTitleSmall, IImgTitle, VTitle },
  created() {
    this.tfNews = {};
  },
  updated() {
    document.dispatchEvent(window.lrLoad);
  },
  computed: {
    ...mapGetters("auth", {
      news: "news",
      user: "user",
      hasLoaded: "hasLoaded",
    }),
  },
};
</script>
