<template>
  <div
    class="twentytwenty-container"
    v-bind:style="containerStyle"
    v-on:touchstart="startSlide"
    v-on:mousedown="startSlide"
  >
    <img
      :src="after"
      alt="antes"
      v-on:mousedown.prevent
      v-on:load="setDimensions"
    />

    <img
      :src="before"
      alt="ahora"
      v-on:mousedown.prevent
      v-bind:style="beforeImgStyle"
    />

    <div class="twentytwenty-overlay" v-bind:style="overlayStyle">
      <div v-if="beforeLabel" class="twentytwenty-before-label">
        {{ beforeLabel }}
      </div>
      <div v-if="afterLabel" class="twentytwenty-after-label">
        {{ afterLabel }}
      </div>
    </div>

    <div
      class="twentytwenty-handle"
      v-bind:style="handleStyle"
      tabindex="0"
      v-on:keydown="handleArrowNavigation"
    >
      <div class="twentytwenty-arrow-left"></div>
      <div class="twentytwenty-arrow-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgOffset: null,
      slideOffset: this.offset,
      sliding: false,
      containerStyle: {},
      overlayStyle: {},
    };
  },
  props: {
    before: {
      type: String,
      required: true,
    },
    beforeLabel: {
      type: String,
    },
    after: {
      type: String,
      required: true,
    },
    afterLabel: {
      type: String,
    },
    offset: {
      type: [String, Number],
      default: 0.5,
      validator: (value) => value > 0 && value <= 1,
    },
    keyboardStep: {
      type: [String, Number],
      default: 0.2,
      validator: (value) => value > 0 && value <= 1,
    },
  },
  methods: {
    setDimensions() {
      const img = this.$el.querySelector("img");
      this.imgOffset = img.getBoundingClientRect();
      this.containerStyle = { width: `${this.w}px`, height: `${this.h}px` };
    },
    startSlide(event) {
      this.sliding = true;
      this.moveSlide(event);
      this.overlayStyle = { opacity: 0 };
    },
    handleArrowNavigation(event) {
      return this.moveSlide(event);
    },
    moveSlide(event) {
      if (this.sliding) {
        var x =
          (event.touches ? event.touches[0].pageX : event.pageX) -
          this.imgOffset.left;
        x = x < 0 ? 0 : x > this.w ? this.w : x;
        return (this.slideOffset = x / this.w);
      }
      if (event.key) {
        switch (event.key) {
          case "Left": // IE/Edge key
          case "ArrowLeft":
            this.slideOffset =
              this.floatOffset - this.floatKeyboardStep >= 0
                ? this.floatOffset - this.floatKeyboardStep
                : 0;
            break;
          case "Right": // IE/Edge key
          case "ArrowRight":
            this.slideOffset =
              this.floatOffset + this.floatKeyboardStep <= 1
                ? this.floatOffset + this.floatKeyboardStep
                : 1;
            break;
          default:
            return;
        }
      }
    },
    endSlide() {
      this.sliding = false;
      this.overlayStyle = {};
    },
    resize() {
      this.containerStyle = {};
      this.$nextTick(() => this.setDimensions());
    },
  },
  computed: {
    beforeImgStyle() {
      return { clip: `rect(0, ${this.x}px, ${this.h}px, 0)` };
    },
    handleStyle() {
      const size = 40;
      return {
        width: `${size}px`,
        height: `${size}px`,
        top: `calc(50% - ${size / 2}px)`,
        left: `calc(${this.slideOffset * 100}% - ${size / 2}px)`,
      };
    },
    x() {
      return this.w * this.slideOffset;
    },
    w() {
      return this.imgOffset ? this.imgOffset.width : null;
    },
    h() {
      return this.imgOffset ? this.imgOffset.height : null;
    },
    floatOffset() {
      return parseFloat(this.slideOffset);
    },
    floatKeyboardStep() {
      return parseFloat(this.keyboardStep);
    },
  },
  mounted() {
    document.addEventListener("touchmove", this.moveSlide);
    document.addEventListener("touchend", this.endSlide);
    document.addEventListener("mousemove", this.moveSlide);
    document.addEventListener("mouseup", this.endSlide);
    window.addEventListener("resize", this.resize);
    document.addEventListener("lrload", this.resize);
  },
  beforeDestroy() {
    document.removeEventListener("touchmove", this.moveSlide);
    document.removeEventListener("touchend", this.endSlide);
    document.removeEventListener("mousemove", this.moveSlide);
    document.removeEventListener("mouseup", this.endSlide);
    window.removeEventListener("resize", this.resize);
    document.removeEventListener("lrload", this.resize);
  },
};
</script>
