var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.post
    ? _c(
        "div",
        {
          staticClass: "news H_img_title",
          class: { "video-play-icon": _vm.post.format === 4 },
        },
        [
          _c("div", { staticClass: "row m-0" }, [
            _c("div", { staticClass: "col-5 col-5 pl-0 pr-3" }, [
              _c("a", { attrs: { href: _vm.post.postUrl } }, [
                _c(
                  "div",
                  { staticClass: "containerVideoImage" },
                  [_c("img-lr", { attrs: { image: _vm.post.principalImage } })],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-7 col-7 pl-0 pr-3" },
              [
                _c("a", {
                  staticClass: "kicker",
                  class: _vm.post.section.className,
                  attrs: { href: _vm.post.postUrl },
                  domProps: { innerHTML: _vm._s(_vm.post.header) },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "date-news",
                  domProps: { innerHTML: _vm._s(_vm.postDate) },
                }),
                _vm._v(" "),
                _c(
                  "text-fill",
                  {
                    attrs: { "group-name": _vm.groupName, "max-font-size": 40 },
                  },
                  [
                    _c("a", {
                      class: _vm.post.section.className,
                      attrs: { href: _vm.post.postUrl },
                      domProps: { innerHTML: _vm._s(_vm.post.title) },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.post.isAnalysis
                  ? _c("div", { staticClass: "autor" }, [
                      _c("a", {
                        attrs: { href: _vm.post.authorUrl },
                        domProps: { innerHTML: _vm._s(_vm.post.authorName) },
                      }),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }