"use strict";
import Vue from "vue";
import { mapGetters } from "vuex";
import { ToastPlugin } from "bootstrap-vue";
Vue.use(ToastPlugin);

export const homeMixin = {
  data: {
    enableRefresh: true,
  },
  created: function () {
    this.$refreshHub.$on("home-refreshed", this.refreshHome);
  },
  mounted: function () {
    //elections timeline control height
    setTimeout(calc_height(), 1000);
    setTimeout(calc_height(), 2000);
    setTimeout(calc_height(), 4000);
    setTimeout(calc_height(), 8000);

    function calc_height() {
      return () => {
        const ele23Post = document.querySelector(".elections-home .news-2 .posts");
        if (ele23Post) {
          const ele23PostHeight = ele23Post.offsetHeight;
          var ele23Live = document.querySelector(".elections-home .news-2 .live");
          if (ele23Live) {
            ele23Live.style.maxHeight = ele23PostHeight + "px";
          }
        }
      };
    }
  },
  methods: {
    refreshHome: function () {
      if (this.enableRefresh) {
        this.enableRefresh = false;
        this.hideMessage();
        this.showMessage("ACTUALIZACIÓN", "Más información de última hora", [
          {
            title: "Actualizar",
            href: "/",
            class: ["btn", "lr"],
          },
        ]);
      }
    },
    hideMessage: function () {
      this.$bvToast.hide();
    },
    showMessage: function (title, message, actions) {
      const _self = this;
      const h = this.$createElement;
      let elMsg = [h("p", message)];
      // Create the message
      if (actions && actions.length) {
        elMsg = elMsg.concat(
          h(
            "div",
            {
              class: ["text-right", "mb-0"],
            },
            actions.map((q) => {
              if (q.modal) {
                return h(
                  "a",
                  {
                    class: q.class,
                    on: {
                      click: function () {
                        _self.$bvToast.hide();
                        _self.openSubscribeModal = true;
                      },
                    },
                  },
                  q.title
                );
              } else {
                return h(
                  "a",
                  {
                    class: q.class,
                    attrs: {
                      href: q.href,
                    },
                  },
                  q.title
                );
              }
            })
          )
        );
      }
      const vNodesMsg = h(
        "div",
        {
          class: ["mb-0"],
        },
        elMsg
      );
      this.$bvToast.hide();
      this.$bvToast.toast([vNodesMsg], {
        title: title,
        toaster: "b-toaster-bottom-right",
        noAutoHide: true,
        appendToast: true,
      });
    },
  },
  computed: {
    ...mapGetters("auth", {
      news: "news",
    }),
  },
};
