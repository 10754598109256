var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.posts
    ? _c("div", { attrs: { "data-mrf-recirculation": "Tendencias" } }, [
        _c("h3", { staticClass: "heading-title" }, [_vm._v("TENDENCIAS")]),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "mt-4",
            attrs: { "data-epica-module-name": "Tendencias" },
          },
          _vm._l(_vm.posts, function (post, idx) {
            return _c("li", { key: post.id }, [
              _c("div", { staticClass: "d-flex" }, [
                _c("div", {
                  staticClass: "numberTends",
                  domProps: { innerHTML: _vm._s(idx + 1) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "V_Trends" }, [
                  _c("a", {
                    staticClass: "kicker",
                    class: post.className,
                    attrs: { href: post.url },
                    domProps: { innerHTML: _vm._s(post.header) },
                  }),
                  _vm._v(" "),
                  _c("h2", [
                    _c("a", {
                      class: post.className,
                      attrs: { href: post.url },
                      domProps: { innerHTML: _vm._s(post.title) },
                    }),
                  ]),
                ]),
              ]),
            ])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }