var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-scroll-zone" },
    [
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.startScroll,
            expression: "startScroll",
          },
        ],
        staticClass: "icon-arrow-left",
      }),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.endScroll,
            expression: "endScroll",
          },
        ],
        staticClass: "icon-arrow-right",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }