<template>
    <div class="h-scroll-zone">
        <i class="icon-arrow-left" v-show="startScroll"></i>
        <slot></slot>
        <i class="icon-arrow-right" v-show="endScroll"></i>
    </div>
</template>
<script>
export default {
    props: {
        itemsContainerClass: String,
    },
    mounted: function () {
        this.scrollZone = document.querySelector(`.h-scroll-zone .${this.itemsContainerClass}`);
        if (this.scrollZone) {
            if (this.scrollZone) {
                this.scrollZone.addEventListener("mousedown", this.sMousedown);
                this.scrollZone.addEventListener("mouseup", this.sMouseup);
                this.scrollZone.addEventListener("mouseleave", this.sMouseup);
                this.scrollZone.addEventListener("mousemove", this.sMousemove);
            }

            document.querySelector(".h-scroll-zone .icon-arrow-left").addEventListener("mousedown", this.prev);
            document.querySelector(".h-scroll-zone .icon-arrow-left").addEventListener("mouseup", this.stopScroll);

            document.querySelector(".h-scroll-zone .icon-arrow-right").addEventListener("mousedown", this.next);
            document.querySelector(".h-scroll-zone .icon-arrow-right").addEventListener("mouseup", this.stopScroll);
        }
    },
    computed: {
        startScroll: function () {
            return this.scrollZone && this.scrollPosition > 0;
        },
        endScroll: function () {
            return this.scrollPosition == 0 || (this.scrollZone && this.scrollPosition < (this.scrollZone.scrollWidth - this.scrollZone.clientWidth));
        }
    },
    methods: {
        sMousedown: function (ev) {
            this.scrolling = true;
        },
        sMouseup: function (ev) {
            this.scrolling = false;
        },
        sMousemove: function (ev) {
            if (this.scrolling) {
                this.scrollZone.scrollLeft -= ev.movementX;
                this.updatePosition();
            }
        },
        updatePosition: function () {
            this.scrollPosition = this.scrollZone.scrollLeft;
        },
        setScrollPosition: function (v) {
            this.scrollZone.scrollLeft += v;
            this.updatePosition();
        },
        prev: function () {
            if (!this.intervalControl) {
                this.intervalControl = setInterval(() => {
                    if (this.startScroll) {
                        this.setScrollPosition(-3);
                    } else {
                        this.stopScroll();
                    }
                }, 10);
            }
        },
        next: function () {
            if (!this.intervalControl) {
                this.intervalControl = setInterval(() => {
                    if (this.endScroll) {
                        this.setScrollPosition(3);
                    } else {
                        this.stopScroll();
                    }

                }, 10);
            }
        },
        stopScroll: function () {
            clearInterval(this.intervalControl);
            this.intervalControl = null;
        }
    },
    data: function () {
        return {
            scrolling: false,
            scrollPosition: 0,
            scrollZone: null,
            intervalControl: null
        };
    },
};
</script>