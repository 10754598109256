var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLoaded && _vm.user
    ? _c(
        "div",
        {
          attrs: {
            "data-epica-module-name": "Mis Noticias",
            "data-mrf-recirculation": "Mis Noticias",
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          !_vm.news || !_vm.news.length
            ? _c("div", { staticClass: "topics notTopics mb-5" }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
              ])
            : _vm.news.length < 5
            ? _c("div", { staticClass: "topics oneTopics notTopics mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8 columns text" }, [
                    _c(
                      "div",
                      { staticClass: "contentText" },
                      [
                        _c("h-img-v-title-lead", {
                          attrs: { post: _vm.news[0], "group-name": "hh" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                ]),
                _vm._v(" "),
                _vm._m(4),
              ])
            : _vm.news.length < 8
            ? _c("div", { staticClass: "topics fiveTopics mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-4 mb-0" },
                    [
                      _c("i-img-title", {
                        attrs: { post: _vm.news[0], "group-name": "ee" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-8" }, [
                    _c(
                      "div",
                      { staticClass: "row row-cols-2 second" },
                      _vm._l(_vm.news.slice(1, 5), function (p) {
                        return _c(
                          "div",
                          { key: p.id, staticClass: "col" },
                          [
                            _c("h-img-title-small", {
                              attrs: { post: p, "group-name": "eb" },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(5),
              ])
            : _c("div", { staticClass: "allTopics mb-5" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-5 mb-0" },
                    [
                      _c("i-img-title", {
                        attrs: { post: _vm.news[0], "group-name": "ee" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 d-flex flex-column justify-content-between",
                    },
                    _vm._l(_vm.news.slice(1, 4), function (p) {
                      return _c("h-img-title-small", {
                        key: p.id,
                        attrs: { post: p, "group-name": "eb" },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-3 d-flex flex-column justify-content-between",
                    },
                    _vm._l(_vm.news.slice(4, 9), function (p) {
                      return _c("v-title", {
                        key: p.id,
                        attrs: { post: p, "group-name": "ec" },
                      })
                    }),
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm._m(6),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex mb-3" }, [
      _c("h3", { staticClass: "heading-title empresasSect topics" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://mas.larepublica.co/noticias",
              target: "_blank",
              rel: "noopener",
            },
          },
          [_vm._v("\n        MIS NOTICIAS\n      ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow-1 lineTitle m-3" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-7" }, [
        _c("div", { staticClass: "text" }, [
          _c("span", [
            _vm._v("Usted no ha seleccionado ningún tema de su interés"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n            Lo invitamos a que seleccione los temas de su preferencia para\n            acceder directamente a las noticias de su interés.\n          "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5 columns" }, [
        _c("ul", { staticClass: "stepsTopics" }, [
          _c("li", [
            _c("strong", [_vm._v("Paso 1: ")]),
            _vm._v("Acceda a\n            "),
            _c(
              "a",
              {
                attrs: {
                  href: "https://mas.larepublica.co/noticias",
                  target: "_blank",
                  rel: "noopener",
                },
              },
              [_vm._v("mas.larepublica.co")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Paso 2: ")]),
            _vm._v("Ingrese a "),
            _c("b", [_vm._v('"temas de interés"')]),
            _vm._v(".\n          "),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Paso 3: ")]),
            _vm._v(
              "Busque y seleccione los temas de su\n            preferencia.\n          "
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Paso 4: ")]),
            _vm._v("Disfrute de las noticas de su interés.\n          "),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "button" }, [
          _c(
            "a",
            {
              staticClass: "btn mytopicSect",
              attrs: {
                href: "https://mas.larepublica.co/noticias/editar-top-20",
                target: "_blank",
                rel: "noopener",
              },
            },
            [
              _c("i", { staticClass: "icon-mode_edit" }),
              _vm._v("ADMINISTRE SUS TEMAS"),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-4 columns" }, [
      _c("p", { staticClass: "invite" }, [
        _vm._v(
          "\n          Lo invitamos a que seleccione los temas de su preferencia para\n          acceder directamente a las noticias de su interés.\n        "
        ),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "stepsTopics" }, [
        _c("li", [
          _c("strong", [_vm._v("Paso 1: ")]),
          _vm._v("Acceda a\n            "),
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("mas.larepublica.co")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 2: ")]),
          _vm._v("Ingrese a "),
          _c("b", [_vm._v('"temas de interés".')]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 3: ")]),
          _vm._v(
            "Busque y seleccione los temas de su\n            preferencia.\n          "
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("strong", [_vm._v("Paso 4: ")]),
          _vm._v("Disfrute de las noticas de su interés.\n          "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "button" }, [
          _c(
            "a",
            {
              staticClass: "btn mytopicSect",
              attrs: {
                href: "https://mas.larepublica.co/noticias/editar-top-20",
                target: "_blank",
                rel: "noopener",
              },
            },
            [
              _c("i", { staticClass: "icon-mode_edit" }),
              _vm._v("ADMINISTRE SUS TEMAS"),
            ]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "button" }, [
          _c(
            "a",
            {
              staticClass: "btn mytopicSect",
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("MIS NOTICIAS")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "button" }, [
          _c(
            "a",
            {
              staticClass: "btn mytopicSect",
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("MIS NOTICIAS")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }