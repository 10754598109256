<template>
  <div class="quote-graph">
    <div class="graph">
      <v-chart ref="vgraph" :autoresize="true" :manual-update="true" />
    </div>
    <div class="graph-buttons" v-if="wb">
      <button
        v-for="item in btns"
        v-bind:class="{ active: item.active }"
        :key="item.idx"
        @click="changeScale(item)"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import ECharts from "vue-echarts";
import axios from "axios";

import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  DatasetComponent,
  GridComponent,
  TooltipComponent,
} from "echarts/components";
import { echartformat } from "../../../functions/general-functions";
Vue.use(VueCompositionAPI);
echarts.use([
  LineChart,
  DatasetComponent,
  GridComponent,
  TooltipComponent,
  CanvasRenderer,
]);

export default {
  components: { "v-chart": ECharts },
  props: {
    withButtons: Boolean,
    st: String,
    de: Number,
    sc: Number,
    tk: Number,
    qId: String,
    qName: String,
    src: String,
  },
  created: function () {
    this.graphData = {
      color: ["#4872b8"],
      title: {
        show: false,
      },
      textStyle: {
        fontFamily: '"Montserrat", sans-serif',
      },
      grid: {
        left: "80",
        right: "20",
        bottom: "20",
        top: "20",
      },
      legend: {
        show: false,
      },
      xAxis: {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        splitLine: {
          show: true,
          interval: "auto",
        },
        interval: 5,
        inverse: true,
      },
      series: [
        {
          type: "line",
          showSymbol: true,
          label: {
            show: false,
          },
          encode: {
            seriesName: [1],
            x: 0,
            y: 1,
          },
        },
      ],
    };
  },
  mounted: function () {
    this.wb = this.withButtons ? this.withButtons : false;
    this.style = this.st ? this.st : "currency";
    this.decimals = this.de ? this.de : 2;
    this.params = {
      scale: this.sc ? this.sc : undefined,
      take: this.tk ? this.tk : undefined,
      qName: this.qName ? this.qName : undefined,
    };
    this.quoteId = this.qId;
    this.source = this.src;
    this.initGraph();
    this.updateGraphicData(this.wb);
  },
  methods: {
    initGraph: function () {
      const _self = this;
      this.graphData.yAxis = {
        show: true,
        scale: true,
        axisLabel: {
          formatter: function (vv) {
            var val = echartformat(vv, _self.style, _self.decimals);
            return val;
          },
        },
      };
      this.graphData.tooltip = {
        trigger: "axis",
        formatter: function (vv) {
          var p = vv[0];
          return (
            p.seriesName +
            "<br/>" +
            echartformat(p.value[1], _self.style, _self.decimals) +
            "<br/>" +
            p.name
          );
        },
      };
    },
    updateGraphicData: function (firstTime) {
      var _self = this;
      axios
        .get(this.url, {
          params: this.params,
        })
        .then(function (d) {
          var data = d.data;
          if (_self.wb && firstTime) {
            _self.btns = data.buttonsData;
          }
          _self.graphData.dataset = {
            source: data.graphData,
          };
          setTimeout(() => {
            _self.$refs.vgraph.setOption(_self.graphData, true, true);
          }, 500);
        });
    },
    changeScale: function (btn) {
      this.btns = this.btns.map((q) => {
        q.active = false;
        return q;
      });
      btn.active = true;
      this.params.scale = btn.scale;
      this.updateGraphicData(false);
    },
  },
  computed: {
    url: function () {
      var _url = "/api/quote/historic/" + this.quoteId;
      switch (this.source) {
        case "bvc":
          _url = "/api/quote/bvc-historic/" + this.quoteId;
          break;
        case "dolarSpot":
          _url = "/api/quote/spot-historic-h";
          break;
      }
      return _url;
    },
  },
  data: function () {
    return {
      wb: false,
      btns: [],
    };
  },
};
</script>