var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "module-Lrplus-benefits",
      attrs: { "data-mrf-recirculation": "Beneficios LR Más" },
    },
    [
      _c("h2", [_vm._v("Beneficios exclusivos para suscriptores")]),
      _vm._v(" "),
      _c("div", { staticClass: "row row-cols-4 justify-content-center" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/noticias",
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "count-container" }, [
                _c("i", { staticClass: "icon-noticias" }),
                _vm._v(" "),
                _vm.user && _vm.user.isSubscriber
                  ? _c("div", { staticClass: "number" }, [_vm._v("1")])
                  : _vm._e(),
              ]),
              _vm._v("\n        MI SELECCIÓN DE NOTICIAS\n      "),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/eventos",
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "count-container" }, [
                _c("i", { staticClass: "icon-eventos" }),
                _vm._v(" "),
                _vm.user && _vm.user.isSubscriber
                  ? _c("div", { staticClass: "number" }, [_vm._v("2")])
                  : _vm._e(),
              ]),
              _vm._v("\n        CENTRO DE CONVENCIONES\n      "),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/articulos-guardados",
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "count-container" }, [
                _c("i", { staticClass: "icon-favoritos" }),
                _vm._v(" "),
                _vm.user && _vm.user.isSubscriber
                  ? _c("div", { staticClass: "number" }, [_vm._v("3")])
                  : _vm._e(),
              ]),
              _vm._v("\n        BIBLIOTECA PERSONAL\n      "),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/notificaciones",
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "count-container" }, [
                _c("i", { staticClass: "icon-notificacion" }),
                _vm._v(" "),
                _vm.user && _vm.user.isSubscriber
                  ? _c("div", { staticClass: "number" }, [_vm._v("4")])
                  : _vm._e(),
              ]),
              _vm._v("\n        NOTIFICACIONES Y ALERTAS\n      "),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/10000-empresas",
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "count-container" }, [
                _c("i", { staticClass: "icon-empresas" }),
                _vm._v(" "),
                _vm.user && _vm.user.isSubscriber
                  ? _c("div", { staticClass: "number" }, [_vm._v("5")])
                  : _vm._e(),
              ]),
              _vm._v("\n        BITÁCORA EMPRESARIAL 10.000 LR\n      "),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              staticClass: "new",
              attrs: {
                href: "https://mas.larepublica.co/libros",
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "count-container" }, [
                _c("i", { staticClass: "icon-libros" }),
                _vm._v(" "),
                _vm.user && _vm.user.isSubscriber
                  ? _c("div", { staticClass: "number" }, [_vm._v("6")])
                  : _vm._e(),
              ]),
              _vm._v("\n        KIOSCO\n      "),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://mas.larepublica.co/version-digital",
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "count-container" }, [
                _c("i", { staticClass: "icon-version-digital" }),
                _vm._v(" "),
                _vm.user && _vm.user.isSubscriber
                  ? _c("div", { staticClass: "number" }, [_vm._v("7")])
                  : _vm._e(),
              ]),
              _vm._v("\n        TINTA DIGITAL\n      "),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.hasLoaded
        ? [
            !_vm.user
              ? [_vm._m(0)]
              : !_vm.user.isSubscriber
              ? [_vm._m(1)]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-suscription" }, [
      _vm._v("\n        Si usted es suscriptor puede\n        "),
      _c(
        "a",
        {
          staticClass: "login",
          attrs: {
            href: "https://mas.larepublica.co/libros",
            target: "_blank",
          },
        },
        [_vm._v("INICIAR SESIÓN")]
      ),
      _vm._v("\n        o de lo contrario\n        "),
      _c(
        "a",
        {
          staticClass: "suscribe",
          attrs: {
            href: "https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica",
            target: "_blank",
          },
        },
        [_vm._v("SUSCRÍBASE AQUÍ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-suscription" }, [
      _c(
        "a",
        {
          staticClass: "suscribe",
          attrs: {
            href: "https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica",
            target: "_blank",
          },
        },
        [_vm._v("SUSCRÍBASE AQUÍ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }