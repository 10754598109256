var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.live
    ? _c(
        "div",
        {
          staticClass: "apertura-inside container-fluid mb-6",
          attrs: {
            "data-epica-module-name": "Apertura En Vivo",
            "data-mrf-recirculation": "Apertura En Vivo",
          },
        },
        [
          _vm.live.feedTitle && _vm.live.feed && _vm.live.feed.length
            ? _c("div", { staticClass: "container" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "flex-grow-1" }, [
                    _c(
                      "div",
                      { staticClass: "V_Video_Title" },
                      [
                        _c("div", {
                          staticClass:
                            "embed-responsive embed-responsive-16by9",
                          domProps: { innerHTML: _vm._s(_vm.live.embedVideo) },
                        }),
                        _vm._v(" "),
                        _c(
                          "text-fill",
                          {
                            attrs: { "group-name": "ih", "max-font-size": 25 },
                          },
                          [
                            _c("a", {
                              staticClass: "insideSect",
                              domProps: { innerHTML: _vm._s(_vm.live.title) },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "feedInside d-flex flex-column" }, [
                    _c("h2", {
                      staticClass: "title-feed-inside",
                      domProps: { innerHTML: _vm._s(_vm.live.feedTitle) },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "wrap-feed d-flex flex-column flex-grow-1",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "feed-inside flex-grow-1" },
                          _vm._l(_vm.live.feed, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "list-feed" },
                              [
                                item.image
                                  ? _c("img", {
                                      attrs: {
                                        src: item.image.url,
                                        alt: item.image.alt,
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "hour",
                                  domProps: { innerHTML: _vm._s(item.time) },
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass: "date",
                                  domProps: { innerHTML: _vm._s(item.date) },
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  domProps: { innerHTML: _vm._s(item.content) },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _c("div", { staticClass: "container without-feed" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _c("div", {
                      staticClass: "embed-responsive embed-responsive-16by9",
                      domProps: { innerHTML: _vm._s(_vm.live.embedVideo) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column title-inside" },
                      [
                        _c("span", { staticClass: "kicker" }, [
                          _vm._v("EN VIVO"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "text-fill",
                          {
                            staticClass: "flex-grow-1",
                            attrs: { "group-name": "ih", "max-font-size": 25 },
                          },
                          [
                            _c("a", {
                              staticClass: "insideSect",
                              domProps: { innerHTML: _vm._s(_vm.live.title) },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row en-vivo" }, [
      _c("div", { staticClass: "col d-flex align-items-center mb-3" }, [
        _c("div", { staticClass: "icon-live" }, [
          _c("img", {
            attrs: {
              src: "https://s.lalr.co/lr/images/icon-live-gif.gif",
              alt: "Icono Live",
            },
          }),
          _c("span", [_vm._v("EN VIVO")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }