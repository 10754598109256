<template>
  <div
    class="module-Lrplus-benefits"
    data-mrf-recirculation="Beneficios LR Más"
  >
    <h2>Beneficios exclusivos para suscriptores</h2>

    <div class="row row-cols-4 justify-content-center">
      <div class="col">
        <a href="https://mas.larepublica.co/noticias" target="_blank">
          <div class="count-container">
            <i class="icon-noticias"></i>
            <div class="number" v-if="user && user.isSubscriber">1</div>
          </div>
          MI SELECCIÓN DE NOTICIAS
        </a>
      </div>
      <div class="col">
        <a href="https://mas.larepublica.co/eventos" target="_blank">
          <div class="count-container">
            <i class="icon-eventos"></i>
            <div class="number" v-if="user && user.isSubscriber">2</div>
          </div>
          CENTRO DE CONVENCIONES
        </a>
      </div>
      <div class="col">
        <a
          href="https://mas.larepublica.co/articulos-guardados"
          target="_blank"
        >
          <div class="count-container">
            <i class="icon-favoritos"></i>
            <div class="number" v-if="user && user.isSubscriber">3</div>
          </div>
          BIBLIOTECA PERSONAL
        </a>
      </div>
      <div class="col">
        <a href="https://mas.larepublica.co/notificaciones" target="_blank">
          <div class="count-container">
            <i class="icon-notificacion"></i>
            <div class="number" v-if="user && user.isSubscriber">4</div>
          </div>
          NOTIFICACIONES Y ALERTAS
        </a>
      </div>
      <div class="col">
        <a href="https://mas.larepublica.co/10000-empresas" target="_blank">
          <div class="count-container">
            <i class="icon-empresas"></i>
            <div class="number" v-if="user && user.isSubscriber">5</div>
          </div>
          BITÁCORA EMPRESARIAL 10.000 LR
        </a>
      </div>
      <div class="col">
        <a href="https://mas.larepublica.co/libros" target="_blank" class="new">
          <div class="count-container">
            <i class="icon-libros"></i>
            <div class="number" v-if="user && user.isSubscriber">6</div>
          </div>
          KIOSCO
        </a>
      </div>
      <div class="col">
        <a href="https://mas.larepublica.co/version-digital" target="_blank">
          <div class="count-container">
            <i class="icon-version-digital"></i>
            <div class="number" v-if="user && user.isSubscriber">7</div>
          </div>
          TINTA DIGITAL
        </a>
      </div>
    </div>
    <template v-if="hasLoaded">
      <template v-if="!user">
        <div class="login-suscription">
          Si usted es suscriptor puede
          <a
            href="https://mas.larepublica.co/libros"
            target="_blank"
            class="login"
            >INICIAR SESIÓN</a
          >
          o de lo contrario
          <a
            href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
            target="_blank"
            class="suscribe"
            >SUSCRÍBASE AQUÍ</a
          >
        </div>
      </template>
      <template v-else-if="!user.isSubscriber">
        <div class="login-suscription">
          <a
            href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
            target="_blank"
            class="suscribe"
            >SUSCRÍBASE AQUÍ</a
          >
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", {
      user: "user",
      hasLoaded: "hasLoaded",
    }),
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
  },
};
</script>
