var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isOpen && _vm.videoEmbed
    ? _c("div", { staticClass: "video-live" }, [
        _c("i", { staticClass: "icon-close", on: { click: _vm.closeVideo } }),
        _vm._v(" "),
        _c("div", {
          staticClass: "embed-responsive embed-responsive-16by9",
          domProps: { innerHTML: _vm._s(_vm.videoEmbed) },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }