<template>
  <div class="mini-quote-graph">
    <v-chart ref="vgraph" :autoresize="true" :manual-update="true" />
  </div>
</template>

<script>
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import ECharts from "vue-echarts";
import axios from "axios";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  DatasetComponent,
  GridComponent,
  TooltipComponent,
} from "echarts/components";
import { echartformat } from "../../../functions/general-functions";
const { use } = echarts;
Vue.use(VueCompositionAPI);
use([
  LineChart,
  DatasetComponent,
  GridComponent,
  TooltipComponent,
  CanvasRenderer,
]);

export default {
  components: { "v-chart": ECharts },
  props: {
    st: String,
    de: Number,
    qId: String,
    qName: String,
    tk: Number,
    sc: Number,
    src: String,
  },
  created: function () {
    this.graphData = {
      color: ["#4872b8"],
      title: {
        show: false,
      },
      legend: {
        show: false,
      },
      textStyle: {
        fontFamily: '"Montserrat", sans-serif',
      },
      grid: {
        left: "0",
        right: "0",
        bottom: "10",
        top: "10",
      },
      xAxis: {
        type: "category",
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        interval: 5,
        inverse: true,
      },
      yAxis: {
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        show: true,
        scale: true,
      },
      series: [
        {
          type: "line",
          showSymbol: true,
          label: {
            show: false,
          },
          encode: {
            seriesName: [1],
            x: 0,
            y: 1,
          },
        },
      ],
    };
  },
  mounted: function () {
    this.style = this.st ? this.st : "currency";
    this.decimals = this.de ? this.de : 2;
    this.params = {
      scale: this.sc ? this.sc : undefined,
      take: this.tk ? this.tk : undefined,
      qName: this.qName ? this.qName : undefined,
    };
    this.quoteId = this.qId;
    this.source = this.src;

    this.initGraph();
    this.updateGraphicData();
  },
  methods: {
    initGraph: function () {
      const _self = this;
      this.graphData.yAxis.axisLabel = {
        formatter: function (vv) {
          var val = echartformat(vv, _self.style, _self.decimals);
          return val;
        },
      };
      this.graphData.tooltip = {
        trigger: "axis",
        formatter: function (params) {
          var p = params[0];
          return (
            p.seriesName +
            "<br/>" +
            echartformat(p.value[1], _self.style, _self.decimals) +
            "<br/>" +
            p.name
          );
        },
      };
    },
    updateGraphicData: function () {
      var _self = this;
      axios
        .get(this.url, {
          params: this.params,
        })
        .then(function (d) {
          var data = d.data;
          _self.graphData.dataset = {
            source: data.graphData,
          };
          setTimeout(() => {
            _self.$refs.vgraph.setOption(_self.graphData, true, true);
          }, 500);
        });
    },
  },
  computed: {
    url: function () {
      var _url = "/api/quote/historic/" + this.quoteId;
      switch (this.source) {
        case "bvc":
          _url = "/api/quote/bvc-historic/" + this.quoteId;
          break;
        case "dolarSpot":
          _url = "/api/quote/spot-historic-h";
          break;
      }
      return _url;
    },
  },
};
</script>
