<template>
  <div class="V_Title" v-if="post">
    <a
      :href="post.isAnalysis ? post.authorUrl : post.postUrl"
      class="kicker"
      :class="post.section.className"
      v-html="post.isAnalysis ? post.authorName : post.header"
    ></a>
    <text-fill
      v-cloak
      :group-name="groupName"
      :max-font-size="40"
      v-on:new-font-size="$emit('tf-update', $event)"
    >
      <a
        :href="post.postUrl"
        :class="post.section.className"
        v-html="post.title"
      ></a>
    </text-fill>
  </div>
</template>

<script>
import { TextFill } from "../../components/text-fill/TextFill.vue";
import moment from "moment";

moment.locale("es-co");
export default {
  components: { TextFill },
  props: {
    post: Object,
    groupName: String,
  },
  computed: {
    postDate() {
      return moment(this.post.create).format("dd/MM/yyyy");
    },
  },
};
</script>