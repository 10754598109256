var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dolar-spot",
      attrs: { "data-mrf-recirculation": "Dolar Spot" },
    },
    [
      _vm.header && _vm.header.print
        ? [
            _c(
              "a",
              { staticClass: "title", attrs: { href: _vm.header.quoteUrl } },
              [_c("h3", { staticClass: "titleModule" }, [_vm._v("DOLAR SPOT")])]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "head", class: _vm.header.print.upDownClass },
              [
                _c("span", {
                  staticClass: "price",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.closingPrice),
                  },
                }),
                _vm._v(" "),
                _c("i", { staticClass: "icon-quote-status" }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "absolute",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.absoluteVariation),
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "percentage",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.percentageVariation),
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("ul", { staticClass: "detail" }, [
              _c("li", [
                _c("h4", { staticClass: "labelDetail" }, [
                  _vm._v("PRECIO DE APERTURA"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.openingPrice),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", { staticClass: "labelDetail" }, [
                  _vm._v("PRECIO PROMEDIO"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: { innerHTML: _vm._s(_vm.header.print.avgPrice) },
                }),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", { staticClass: "labelDetail" }, [
                  _vm._v("PRECIO MÁXIMO"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: { innerHTML: _vm._s(_vm.header.print.maxPrice) },
                }),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", { staticClass: "labelDetail" }, [
                  _vm._v("PRECIO MÍNIMO"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: { innerHTML: _vm._s(_vm.header.print.minPrice) },
                }),
              ]),
              _vm._v(" "),
              _c("li", [
                _c("h4", { staticClass: "labelDetail" }, [
                  _vm._v("NÚMERO DE TRANSACCIONES"),
                ]),
                _vm._v(" "),
                _c("span", {
                  staticClass: "price",
                  domProps: {
                    innerHTML: _vm._s(_vm.header.print.transactionCount),
                  },
                }),
              ]),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dolar-spot-graph" }, [
        _c(
          "div",
          { staticClass: "graph" },
          [
            _c("v-chart", {
              ref: "vgraph",
              attrs: { autoresize: true, "manual-update": true },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.wb
          ? _c(
              "div",
              { staticClass: "graph-buttons" },
              _vm._l(_vm.btns, function (item) {
                return _c(
                  "button",
                  {
                    key: item.idx,
                    class: { active: item.active },
                    on: {
                      click: function ($event) {
                        return _vm.changeScale(item)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex footer" }, [
        _c("div", { staticClass: "flex-grow-1" }, [
          _c("span", { staticClass: "date" }, [
            _c("strong", [_vm._v("Fecha:")]),
            _vm._v("\n        " + _vm._s(_vm.spotDate) + "\n      "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "hour" }, [
            _c("strong", [_vm._v("Hora:")]),
            _vm._v("\n        " + _vm._s(_vm.spotTime) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btFuente" }, [
      _c("span", [_c("strong", [_vm._v("Fuente:")])]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://dolar.set-icap.com/",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_c("span", [_vm._v("Set-FX")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }