var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "img-wrap" }, [
    _c("img", {
      staticClass: "lazyload",
      attrs: {
        "v-if": _vm.image,
        width: _vm.image.width,
        height: _vm.image.height,
        src:
          "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 " +
          _vm.image.width +
          " " +
          _vm.image.height +
          "'%3E%3C/svg%3E",
        "data-src": _vm.image.url,
        alt: _vm.image.alt,
        title: _vm.image.title,
        "data-index": _vm.index,
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }