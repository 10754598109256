export const ripeTabsMixin = {
  methods: {
    changeRipeTab: function (idx) {
      this.actualGloboTab = idx;
    },
  },
  data: function () {
    return {
      actualGloboTab: 1,
    };
  },
};
