<template>
  <div class="multiple_graphic_echart">
    <div class="graph">
      <v-chart ref="vgraph" :autoresize="true" :manual-update="true" />
    </div>
    <div class="graph-buttons" v-if="!hideButtons">
      <button
        v-for="item in btns"
        :class="{ active: item.active }"
        :key="item.key"
        @click="changeScale(item)"
      >
        {{ item.name }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ECharts from "vue-echarts";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  DatasetComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
} from "echarts/components";
import { echartformat } from "../../../functions/general-functions";

echarts.use([
  TitleComponent,
  LineChart,
  DatasetComponent,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  CanvasRenderer,
]);

export default {
  components: { "v-chart": ECharts },
  props: {
    initDataset: Array,
    initParams: Object,
    colors: Array,
    hideButtons: Boolean,
  },
  data() {
    return {
      dataset: null,
      graphData: null,
      btns: [
        { name: "6M", key: "R6M", active: false },
        { name: "1A", key: "R1A", active: false },
        { name: "3A", key: "R3A", active: false },
        { name: "5A", key: "R5A", active: false },
      ],
    };
  },

  watch: {
    initParams: {
      handler(newval) {
        this.updateGraphicData();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      return this.initParams;
    },
    showLegend() {
      return this.dataset[0].length > 2;
    },
    legendTopSpace() {
      const legendSize =
        Math.ceil(this.dataset[0].slice(1).join(" ").length / 150) * 20 + 40;
      return Math.max(60, legendSize);
    },
  },
  mounted() {
    this.dataset = this.initDataset;
    this.btns = this.btns.map((btn) => {
      if (btn.key === this.params.r) {
        btn.active = true;
      }
      return btn;
    });

    this.initGraph();
    this.loadGraph();
  },
  methods: {
    changeScale: function (btn) {
      this.btns = this.btns.map((q) => {
        q.active = false;
        return q;
      });
      btn.active = true;
      this.params.r = btn.key;
      this.updateGraphicData();
    },
    updateGraphicData: function () {
      var _self = this;
      const qSearch = this.getQueryParams();
      axios.get(`/api/quote/ipc?${qSearch}`).then(function (d) {
        _self.dataset = d.data;
        _self.loadGraph();
      });
    },
    getQueryParams() {
      const ret = new URLSearchParams();
      if (this.params.s) {
        ret.append("s", this.params.s);
      }
      if (this.params.r) {
        ret.append("r", this.params.r);
      }
      if (this.params.pp) {
        this.params.pp.forEach((el) => {
          ret.append("pp", el);
        });
      }
      if (this.params.cc) {
        this.params.cc.forEach((el) => {
          ret.append("cc", el);
        });
      }
      if (this.params.ss) {
        this.params.ss.forEach((el) => {
          ret.append("ss", el);
        });
      }
      return ret;
    },
    initGraph() {
      this.graphData = {
        color: this.colors
          ? this.colors
          : ["#0071bc", "#39b54a", "#f7931e", "#ed1c24", "#93278f", "#ed1e79"],
        title: {
          show: false,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            var resTooltip = params[0].name + "<br/>";
            params.forEach(function (el) {
              const valRef = el.value[el.seriesIndex + 1];
              if (valRef) {
                resTooltip =
                  resTooltip +
                  el.marker +
                  el.dimensionNames[el.seriesIndex + 1] +
                  " " +
                  echartformat(el.value[el.seriesIndex + 1], "percent", 2) +
                  "<br/>";
              }
            });
            return resTooltip;
          },
        },
        textStyle: {
          fontFamily: '"Montserrat", sans-serif',
        },
        legend: {
          show: this.showLegend,
          bottom: 10,
        },
        grid: {
          left: "80",
          right: "20",
          bottom: this.showLegend ? "60" : "20",
          top: "20",
        },
        xAxis: {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          splitLine: {
            show: true,
            interval: "auto",
          },
        },
        yAxis: {
          axisLabel: {
            formatter: function (vv) {
              var val = echartformat(vv, "percent", 2);
              return val;
            },
          },
        },
      };
    },
    loadGraph() {
      this.graphData.dataset = { source: this.dataset };
      this.graphData.series = this.dataset[0].slice(1).map(() => {
        return {
          type: "line",
          connectNulls: true,
        };
      });
      if (this.showLegend) {
        this.graphData.grid.bottom = this.legendTopSpace;
      }
      this.$refs.vgraph.setOption(this.graphData, true);
    },
  },
};
</script>
